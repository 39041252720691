<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <app-collapse>
                    <app-collapse-item title="Búsqueda"
                                       :is-visible="isVisible">
            <b-row>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Nombre
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    url="reporte-cursos19/name/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectName"
                                    label="cp_nombre"
                                    id="cp_nombre"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Apellidos
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    url="reporte-cursos19/surname/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectSurName"
                                    label="cp_apellido"
                                    id="cp_apellido"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Rut y Dv
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    url="reporte-cursos19/rut_dv/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectRutDv"
                                    label="rut_dv"
                                    id="rut_dv"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Código Sence
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    url="reporte-cursos19/sence_code/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectCodigoSence"
                                    label="cp_codigoSence"
                                    id="cp_codigoSence"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Sucursal
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    v-model="filter.cp_sucursal"
                                    url="reporte-cursos19/sucursal/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectSucursal"
                                    label="cp_sucursal"
                                    id="cp_sucursal"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Ficha
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    url="reporte-cursos19/ficha/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectFicha"
                                    label="cp_ficha"
                                    id="cp_ficha"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <h5 class="font-weight-bold">
                            Fecha Inicio
                        </h5>
                        <div class="input-group">
                            <flat-pickr
                                    v-model="filter.cp_fechaInicio"
                                    class="form-control"
                                    placeholder="Click para seleccionar..."
                                    :config="config"
                                    reset-button
                            />
                            <div class="input-group-append">
                                <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                    <feather-icon
                                            icon="XIcon"
                                    />
                                </button>
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <h5 class="font-weight-bold">
                            Fecha Término
                        </h5>
                        <div class="input-group">
                            <flat-pickr
                                    v-model="filter.cp_fechaTermino"
                                    class="form-control"
                                    placeholder="Click para seleccionar..."
                                    :config="config"
                                    reset-button
                            />
                            <div class="input-group-append">
                                <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                    <feather-icon
                                            icon="XIcon"
                                    />
                                </button>
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Curso
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    v-model="filter.cp_aplicacion"
                                    url="reporte-cursos19/application/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectApplication"
                                    label="cp_aplicacion"
                                    id="cp_aplicacion"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                    >
                        <h5 class="font-weight-bold">
                            Empresa
                        </h5>
                        <div class="form-label-group">
                            <autocomplete-infinity
                                    v-model="filter.cp_empresa"
                                    url="reporte-cursos19/enterprise/list"
                                    placeholder="Escriba para seleccionar"
                                    @item-selected="selectEnterprise"
                                    label="cp_empresa"
                                    id="cp_empresa"
                                    itemsPerPage="10"
                            ></autocomplete-infinity>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <div class="text-right">
                        <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                type="submit"
                                @click="buscar_cursos_data(filter)">
                            <feather-icon icon="SearchIcon" class="mr-50" />
                            <span class="align-middle">Buscar</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-card>
            <b-card>
                <h4 class="card-title">Repotes Cursos 1.9</h4>
                <div v-if="visibleReport">
                    <b-row class="pr-1 pl-1">
                        <b-col sm="12" md="6"  class="my-1 text-right">
                            <div class="d-flex align-items-center mb-1 mb-md-0">
                                <label>Mostrar</label>
                                <b-form-select
                                        @change="getList"
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                        class="mx-50 col-md-2"
                                ></b-form-select>
                                <label>filas por página</label>
                            </div>
                        </b-col>
                        <b-col sm="12" md="6"  class="my-1 text-right">
                            <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="outline-primary"
                                    type="submit"
                                    class="ml-1"
                                    @click="exportExcelCourseData19">
                                <feather-icon
                                        icon="DownloadIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Exportar</span>

                            </b-button>
                        </b-col>
                    </b-row>
                    <b-col cols="12" v-if="showTable">
                        <b-table
                                striped
                                hover
                                small
                                :per-page="perPage"
                                :items="listado"
                                :fields="fields"
                                responsive
                                class="mb-0"
                                id="my-table"
                                ref="table"
                                show-empty
                        >
                            <template #empty="scope">
                                <div class="text-center">No existen resultados</div>
                            </template>
                        </b-table>

                    </b-col>

                    <b-row class="pl-1">
                        <b-col  cols="8" class="mt-3">
                            <div v-if="totalRows > 0">
                                <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                            </div>
                        </b-col>

                        <b-col cols="4">
                            <b-pagination
                                    @change="onPageChange"
                                    v-model="page"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="right"
                                    class="mt-3 mr-1"
                                    aria-controls="my-table"
                            />
                        </b-col>

                    </b-row>
                </div>

            </b-card>
        </b-overlay>

        <b-modal ref="modalExport"
                 title="Información"
                 ok-only
                 ok-title="Cerrar">
            <p class="my-4"><b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe</p>
        </b-modal>
    </div>
</template>

<script>
    import {
        BTable,
        BProgress,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BTfoot,
        BTr,
        BTh,
        BFormCheckbox,
        BButton,
        BTooltip, BFormDatepicker
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    export default {
        name: "ReportesCursos19",
        components: {
            BTable,
            BProgress,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BFormSelect,
            BTfoot,
            BTr,
            BTh,
            BFormCheckbox,
            BButton,
            BTooltip,
            AppCollapseItem,
            AppCollapse,
        },
        directives: {
            Ripple,
        },
        data() {
            return {
                showTable: true,
                visibleReport: true,
                perPage: 50,
                reportType: 1,
                currentPageV: 1,
                page: 1,
                list:[],
                columnsData:[],
                dataDetalle:[],
                isVisible:true,
                filter: {
                    cp_nombre: null,
                    cp_apellido: null,
                    rut_dv: null,
                    cp_fono1: null,
                    cp_correo1: null,
                    cp_sucursal: null,
                    cp_primerAcceso: null,
                    cp_ultimoAcceso: null,
                    cp_participacion: null,
                    cp_promedio: null,
                    cp_situacion: null,
                    cp_empresa: null,
                    cp_ficha: null,
                    cp_aplicacion: null,
                    cp_costo: null,
                    cp_codigoSence: null,
                    cp_fechaInicio: null,
                    cp_fechaTermino: null,
                    fields:[]
                },
                sortField: 'cp_nombre',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                sortDesc: false,
                showColumn: false,
                showColumnText: 'Mostrar Columnas',
                fields: [
                    { key: "cp_nombre", label: "Nombre", display: true },
                    { key: "cp_apellido", label: "Apellidos", display: true },
                    { key: "rut_dv", label: "Rut_Dv", display: true },
                    { key: "cp_fono1", label: "Fono", display: true },
                    { key: "cp_correo1", label: "Correo", display: true },
                    { key: "cp_sucursal", label: "Sucursal", display: true },
                    { key: "cp_primerAcceso", label: "Primer Acceso", display: true },
                    { key: "cp_ultimoAcceso", label: "Último Acceso", display: true },
                    { key: "cp_participacion", label: "Participación", display: true },
                    { key: "cp_promedio", label: "promedio", display: true },
                    { key: "cp_situacion", label: "Situación", display: true },
                    { key: "cp_empresa", label: "Empresa", display: true },
                    { key: "cp_ficha", label: "Ficha", display: true },
                    { key: "cp_aplicacion", label: "Aplicación", display: true },
                    { key: "cp_costo", label: "Costo", display: true },
                    { key: "cp_codigoSence", label: "Código Sence", display: true },
                    { key: "cp_fechaInicio", label: "Fecha inicio", display: true },
                    { key: "cp_fechaTermino", label: "Fecha término", display: true },
                ],
                pageOptions: [50, 100, 500, 1000],
                transProps: {
                    // Transition name
                    name: 'flip-list'
                },
            }
        },
        mounted() {
        },
        methods: {
            buscar_cursos_data(filter) {
                this.filter = filter;
                this.isVisible = false
                this.page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page = page;
                //this.currentPageV = page
                this.getList();
            },
            getList() {
                let filterData = { ...{
                        'sort_by': this.sortField,
                        'page': this.page,
                        'perPage': this.perPage },
                        ...this.filter
                }
                this.$store.dispatch('reportesCursos19/getList', filterData)
            },
            selectName(data) {
                console.log(data , 'data')
                this.filter.cp_nombre = data != null ? data.cp_nombre : null;
            },
            selectSurName(data) {
                this.filter.cp_apellido = data != null ? data.cp_apellido : null;
            },
            selectRutDv(data) {
                this.filter.rut_dv = data != null ? data.rut_dv : null;
            },
            selectApplication(data) {
                this.filter.cp_aplicacion = data != null ? data.cp_aplicacion : null;
            },
            selectEnterprise(data) {
                this.filter.cp_empresa = data != null ? data.cp_empresa : null;
            },
            selectSucursal(data) {
                this.filter.cp_sucursal = data != null ? data.cp_sucursal : null;
            },
            selectFicha(data) {
                this.filter.cp_ficha = data != null ? data.cp_ficha : null;
            },
            selectCodigoSence(data) {
                this.filter.cp_codigoSence = data != null ? data.cp_codigoSence : null;
            },
            exportExcelCourseData19() {
                if (this.listado.length == 0) {
                    this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
                        title: `ALERTA!!`,
                        variant: 'danger',
                        solid: true,
                    })
                }else {
                    this.excelExportData();
                }
            },
            excelExportData() {
                let filterData = { ...{
                        'sort_by': this.sortField,
                        'page': this.page,
                        'perPage': this.perPage,
                        'exportInBack': false
                    },
                    ...this.filter,
                    fieldDoc: this.fields
                }
                if(this.totalRows > 2000){
                    filterData.exportInBack = true
                    this.$refs.modalExport.show();
                }

                this.$store.dispatch('reportesCursos19/excelExportData', filterData)

            },
        },

        computed: {
            totalRows() {
                return this.$store.state.reportesCursos19.rowsTotal;
            },
            to() {
                return this.$store.state.reportesCursos19.to;
            },
            from() {
                return this.$store.state.reportesCursos19.from;
            },
            listado() {
                return this.$store.state.reportesCursos19.list
            },
            currentPage() {
                return this.$store.state.reportesCursos19.currentPage
            },
            loading() {
                this.visibleReport = !this.$store.state.reportesCursos19.loading

                return  this.$store.state.reportesCursos19.loading
            },
            config() {
                return {
                    locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
                    dateFormat: "d/m/Y",
                    wrap: true,
                }
            }

        },

        watch: {
        }
    }

</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>